import React, { useState } from "react"
import * as S from "./styles"
import { FiTrash } from "react-icons/fi"

const InputFile = ({
  placeholder,
  name,
  styles,
  handleFunction,
  error,
  ...props
}) => {
  const [nameFile, setNameFile] = useState("")

  const handleClick = event => {
    setNameFile(event?.target?.files[0]?.name)
    handleFunction(event)
  }

  const handleRemoveFile = () => {
    setNameFile("")
    window.document.getElementById(name).value = null
    let event = {
      target: {
        type: "file",
        name,
        value: "",
      },
    }

    handleFunction(event)
  }

  return (
    <S.Container style={styles}>
      {placeholder && <label htmlFor={name}>{placeholder}</label>}
      <div className="content-file">
        <div className="input-button">
          <button>
            Upload
            <input
              type="file"
              id={name}
              name={name}
              className="file_input"
              onChange={handleClick}
              {...props}
            />
          </button>
        </div>
        {nameFile && (
          <div className="view-file">
            <span>{nameFile}</span>
            <button onClick={handleRemoveFile}>
              <FiTrash />
            </button>
          </div>
        )}
      </div>
      <span className="input-description">
        <p>Formatos aceitos: {props.accept ?? '*'}</p>
      </span>
      {error && <span className="error">{error.message || error}</span>}
    </S.Container>
  )
}

export default InputFile
