import React from "react"

const Input = ({ label, Style, description, error, ...props }) => {
  return (
    <div className="input-container">
      {label && <label htmlFor={props?.name}>{label}</label>}
      <input {...props} className="default-input"/>
      {description && <span className="input-description">{description}</span>}
      {error && <span className="input-error">{error.message || error}</span>}
    </div>
  )
}

export default Input
