import React, { useState } from "react"
import { useDebounceLocationSearch } from '../../hooks/useDebounceLocationSearch'
import { LocationOption } from "./location-option"

interface LocationInput {
  location: string,
  latitude: number,
  longitude: number
}

interface LocationInputProps {
  label: string,
  isRequired: boolean,
  handleSelectLocation(value: string): void,
  error?: {
    message: string
  }
}

export function LocationInput({label, handleSelectLocation, error, isRequired} : LocationInputProps){
  const [search, setSearch] = useState('')
  const [hasChosen, setHasChosen] = useState(false)
  const result = useDebounceLocationSearch(search, 500)

  const handleSelectOption = (placeId: string, title: string) => {
    handleSelectLocation(placeId)
    setSearch(title)
    setHasChosen(true)
  }

  const handleSearch = (value: string) => {
    if(hasChosen) setHasChosen(false)
    setSearch(value)
  }

  return (
    <div className="location-input-wrapper">
      <div className="input-container">
        <label>{label} {isRequired ? "*" : ""}</label>
        <input
          className="default-input"
          value={search}
          onChange={e => handleSearch(e.target.value)}
          placeholder="Ex: Rua Fidêncio Ramos, 308, Porto, São Paulo - SP"
        />
        {error && <span className="input-error">{error.message || error}</span>}
      </div>
      <div className="location-input-options">
        {!hasChosen && result.map(option => (
          <LocationOption
            title={option.description}
            placeId={option.place_id}
            key={option.placeId}
            handleClick={handleSelectOption}
          />
        ))}
      </div>
    </div>
  )
}
