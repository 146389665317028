import { darken } from "polished"
import styled from "styled-components"

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .content-file {
    display: flex;

    @media (max-width: 500px) {
      flex-direction: column;
    }
    justify-content: flex-start;
    align-items: center;
  }

  .view-file {
    width: 100%;
    display: flex;
    align-items: center;
    margin-left: 16px;
    flex-wrap: nowrap;

    span {
      max-width: 300px;
      font-size: 15px;
      font-weight: 300;
      color: #626262;
      cursor: default;
      white-space: nowrap;
      width: 84%;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    button {
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      border: none;
      outline: none;
      background-color: transparent;
      color: #e8494d;
      cursor: pointer;
      :hover {
        color: ${darken(0.3, "#e8494d")};
      }
    }
  }
  .input-button {
    button {
      position: relative;
      height: 38px;
      border: 1px solid transparent;
      background-color: #42ec9a;
      border-radius: 4px;
      padding: 0 24px;
      cursor: pointer;
      outline: none;

      :hover {
        background-color: ${darken(0.03, "#42ec9a")};
      }

      :active,
      :focus {
        background-color: ${darken(0.1, "#42ec9a")};
      }

      input.file_input {
        border: 1px solid red;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        cursor: pointer;

        ::-webkit-file-upload-button {
          cursor: pointer;
        }
      }
    }
  }

  span {
    margin-block-start: 0;
    margin-block-end: 0;
    font-size: 14px;

    p {
      margin-block-start: 0;
      margin-block-end: 0;
      font-size: 14px;
      color: #a3a3a3;
    }
  }

  span.error {
    color: red;
    margin-block-start: 0;
    margin-block-end: 0;
    font-size: 14px;
  }
`
