import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { getLocationResult } from "../service/index"

export function useDebounceLocationSearch(value: string, delay: number) {
  const [result, setResult] = useState([]);

  useEffect(() => {
    if(!value.length) return
    const handler = setTimeout(async () => {
      try {
        const searchResult = await getLocationResult(value)
        setResult(searchResult);
      }catch(err){
        toast.error('Erro ao buscar pelo endereço solicitado')
      }
    }, delay);

    return () => {
      clearTimeout(handler);
    }},
    [value, delay]
  );

  return result;
}
