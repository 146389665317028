import React from "react"

import JobQuestionnaire from "../../../components/JobQuestionnaire"
import Layout from "../../../components/layout/layout"

const jobForm = ({ jobId, department }) => {
  return (
    <Layout
      pageTitle="Formulario de Inscrição"
      pageDescription="Candidate-se para a vaga."
    >
      <JobQuestionnaire team={department} jobId={jobId} />
    </Layout>
  )
}

export default jobForm
