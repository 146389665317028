import React from 'react';

interface LocationOptionProps {
  title: string
  placeId: string
  handleClick(placeId: string, title: string): void,
}

export function LocationOption({title, placeId, handleClick} : LocationOptionProps){
  return(
    <span onClick={() => handleClick(placeId, title)}>
      {title}
    </span>
  )
}
