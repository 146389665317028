import Select from "../select";
import React from "react";

export const QuestionSingleSelect = ({field, question, index, formErrors, handleSelectChange}) => {
  const selectOptions = field.values.map(value => {
    return {
      ...value,
      label: value.label
        .replace("Yes", "Sim")
        .replace("No", "Não"),
    }
  })

  return (
    <Select
      label={
        index < 1 &&
        `${question.label} ${question.required ? "*" : ""}`
      }
      options={selectOptions}
      onChange={event =>
        handleSelectChange(event.value, field.name)
      }
      Styles={{ marginBottom: 16 }}
      error={formErrors[field.name]}
    />
  )
}
