import styled, { keyframes } from "styled-components"
import { lighten } from "polished"


const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  width: 100%;

  div.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    max-width: 832px;
  }

  div.briefing-job {
    @media (max-width: 848px) {
      padding: 0 16px;
    }
  }
  h2.title {
    margin-block-start: 0.83em;
    margin-block-end: 0;
    font-size: 22px;
    padding: 0 16px;
  }

  p.subtitle {
    margin-block-start: 0;

    font-size: 16px;
  }

  form.form-questions {
    width: 100%;
    max-width: 496px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #eff4f8;
    padding: 40px;
    margin: 16px 0;
    label.form-title {
      width: 100%;
      max-width: 496px;
      font-size: 22px;

      margin: 0 16px 16px 16px;
    }
    div.container-button {
      width: 100%;
      max-width: 496px;

      display: flex;
      justify-content: flex-end;
      margin-top: 16px;
    }

    button.button {
      height: 38px;
      padding: 0 16px;
      background: #0fcc7d;
      cursor: pointer;

      box-shadow: 0 0 4px 2px rgba(66, 236, 154, 0.5);
      border-radius: 32px;
      border: none;
      outline: none;
      :hover {
        background-color: ${lighten(0.04, "#0fcc7d")};
      }
    }
  }
`

export const LabelView = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: -8px;
  margin-bottom: 8px;
`

export const Spinner = styled.div`
  margin: 0 76px;
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);

  border-top: 2px solid #3333;
  border-right: 2px solid #3333;
  border-bottom: 2px solid #3333;
  border-left: 4px solid #ffff;
  background: transparent;
  width: 24px;
  height: 24px;
  border-radius: 50%;
`;
