import React from "react"
import ReactHtmlParser from "react-html-parser"
import { LabelView } from "../JobQuestionnaire/styles"
import Input from "../Input"

export const QuestionPrimary = ({
  index,
  field,
  question,
  formErrors,
  formData,
  handleInputChange,
}) => {
  return (
    <React.Fragment key={`card-technology-others-${index}`}>
      {index > true && <LabelView>Ou</LabelView>}
      <Input
        label={index < 1 && `${question.label} ${question.required ? "*" : ""}`}
        placeholder={question.label}
        type={field.type.replace("input_", "")}
        name={field.name}
        value={formData[field.name]?.value}
        onChange={handleInputChange}
        description={ReactHtmlParser(question.description)}
        Style={{ maxWidth: 496, marginBottom: 16 }}
        error={formErrors[field.name]}
      />
    </React.Fragment>
  )
}
