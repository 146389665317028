import React, { useCallback, useEffect, useState } from "react"
import ReactHtmlParser from "react-html-parser"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import { getJobForm, postJobForm } from "../../service"
import { jobsBoardResource } from "../../resources/jobsBoard"
import { QuestionInputFile } from "../QuestionInputFile"
import { QuestionSingleSelect } from "../QuestionSingleSelect"
import { QuestionMultiSelect } from "../QuestionMultiSelect"
import { QuestionPrimary } from "../QuestionPrimary"
import { LocationInput } from "../LocationInput"

import * as Styles from "./styles"

const SELECT_YES_NO_VALUES = {
  yes: 1,
  no: 0
}

const removeUnusedQuestionNotRequired = (questions, nameQuestionToRemove) => {
  return questions.filter(question => {
    if(question.fields[0]?.name === nameQuestionToRemove && !question.required) return
    else return question
  })
}

const FORM_ERRORS = {
  mandatory_field: 'Campo é obrigatório',
  unexpected_error: 'Ocorreu um erro inesperado, verifique sua conexão e tente novamente!'
}

const JobQuestionnaire = ({ team, jobId }) => {
  const [job, setJob] = useState({})
  const [questions, setQuestions] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [formData, setFormData] = useState({})
  const [formErrors, setFormErrors] = useState({})

  const init = async jobId => {
    setJob(await getJobForm({ department: team, jobId }))
  }

  const updateFormData = (fieldName, value) => {
    setFormData({
      ...formData,
      [fieldName]: value,
    })
  }

  const handleInputChange = event => {
    const target = event.target
    const value =
      target.type === "checkbox"
        ? target.checked
        : target.type === "file" && target?.files?.length > 0
        ? target.files[0]
        : target.value

    const fieldName = target.name
    updateFormData(fieldName, value)
  }

  const handleSelectChange = (value, name) => {
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const handleDataLayer = () => {
    const JobProfile =
      Object.values(jobsBoardResource).find(({ path }) => path === team) ||
      Object.values(jobsBoardResource.comercial.tabs).find(
        ({ path }) => path === team
      )

    const JobTeam =
      Object.getOwnPropertyNames(jobsBoardResource)[
        Object.values(jobsBoardResource.comercial.tabs).find(
          ({ path }) => path === team
        )
          ? 0
          : Object.values(jobsBoardResource).findIndex(
              ({ path }) => path === team
            )
      ]

    window.dataLayer = window.dataLayer || []
    return window.dataLayer.push({
      event: "application_submission",
      team: JobTeam.toLowerCase().replace(/(?:(^.)|(\s+.))/g, match => {
        return match.charAt(match.length - 1).toUpperCase()
      }),
      profile: JobTeam === "comercial" ? JobProfile.name : "",
    })
  }

  const handleSubmitForm = useCallback(
    async event => {
      event.preventDefault()
      setIsLoading(true)

      try {
        const validateForm = questions.filter(question => question.required)
        setFormErrors({})
        let errorObj = {}

        validateForm.forEach(field => {
          const fieldName = field.fields[0].name
          const fieldValue = formData[fieldName]
          if (fieldValue !== SELECT_YES_NO_VALUES.no && !fieldValue){
            errorObj[fieldName] = FORM_ERRORS.mandatory_field
          }
        })

        setFormErrors(errorObj)

        if (!Object.keys(errorObj).length) {
          const queryData = window
            ? JSON.parse(sessionStorage.getItem("queryData") || "[]")
            : []

          let newFormData = new FormData()
          newFormData.append("jobId", jobId)
          newFormData.append("team", team)

          const ghScr = queryData.find(item => item.key === "gh_src")

          if (ghScr) {
            newFormData.append("mapped_url_token", ghScr.value)
          }

          Object.keys(formData).forEach(dataForm => {
            const name = dataForm
            const value = formData[name]
            newFormData.set(name, value)
          })

          await postJobForm(newFormData)

          handleDataLayer()

          return (
            toast.success("Formulário enviado com sucesso!") &&
            setTimeout(() => {
              window && (window.location.href = "/times")
            }, 2000)
          )
        }
      } catch (error) {
        toast.error(FORM_ERRORS.unexpected_error)
      } finally {
        setIsLoading(false)
      }
    },
    [job, formData, questions]
  )

  useEffect(() => {
    if(questions.length) return
    if(job?.questions){
      const jobsFiltered = removeUnusedQuestionNotRequired(job.questions, 'cover_letter')
      setQuestions([...jobsFiltered])
    }
    if(job?.location_questions && job?.location_questions[0]?.required){
      const locationQuestion = {
        label: 'Endereço',
        fields: [{
          type: 'location-input',
          name: 'place_id',
        }],
        required: true,
      }
      setQuestions(prev => [...prev, locationQuestion])
    }
  }, [job])

  useEffect(() => {
    init(jobId).catch()
  }, [jobId])

  return (
    <Styles.Container>
      <ToastContainer />
      {job?.questions && (
        <div className="content">
          <h2 className="title">{job.title}</h2>
          <p className="subtitle">{job?.location?.name}</p>
          <div
            className="briefing-job"
            dangerouslySetInnerHTML={{ __html: ReactHtmlParser(job.content) }}
          />
          <form onSubmit={handleSubmitForm} className="form-questions">
            <label className="form-title">Faça a sua inscrição</label>
            {questions.map((question, i) => (
              <React.Fragment key={`card-technology-${i}`}>
                {question?.fields.map((field, index) => {
                  switch(field.type) {
                    case 'input_file':
                      return (
                        <QuestionInputFile
                          key={`input-name-${field.name}-${index}`}
                          field={field}
                          formErrors={formErrors}
                          question={question}
                          index={index}
                          handleInputChange={handleInputChange}
                        />
                      )
                    case 'multi_value_single_select':
                      return (
                        <QuestionSingleSelect
                          field={field}
                          key={`multi-value-${field.name}-${index}`}
                          question={question}
                          index={index}
                          formErrors={formErrors}
                          handleSelectChange={handleSelectChange}
                        />
                      )
                    case 'multi_value_multi_select':
                      return (
                        <QuestionMultiSelect
                          field={field}
                          question={question}
                          formErrors={formErrors}
                          index={index}
                          key={`select-multi-${index}`}
                          handleSelectChange={handleSelectChange}
                        />
                      )
                    case 'location-input':
                      return (
                        <LocationInput
                          label={question.label}
                          isRequired={question.required}
                          handleSelectLocation={value => updateFormData(field.name, value)}
                          error={formErrors[field.name]}
                          key={`location-input-${index}`}
                        />
                      )
                    default:
                      if(index >= 1) return <></>
                      return (
                        <QuestionPrimary
                          index={index}
                          key={`input-others-${index}`}
                          field={field}
                          question={question}
                          formErrors={formErrors}
                          formData={formData}
                          handleInputChange={handleInputChange}
                    />)
                  }
                })}
              </React.Fragment>
            ))}
            <div className="container-button">
              <button
                type="submit"
                className="button"
                disabled={isLoading}
                style={{
                  ...(isLoading
                    ? { cursor: "not-allowed", pointerEvents: "none" }
                    : {}),
                }}
              >
                {isLoading ? <Styles.Spinner /> : "ENVIAR CANDIDATURA"}
              </button>
            </div>
          </form>
        </div>
      )}
    </Styles.Container>
  )
}

export default JobQuestionnaire
