import Select from "../select";
import React from "react";

export const QuestionMultiSelect = ({field, question, formErrors, index, handleSelectChange}) => {
  const selectOptions = field.values.map(value => {
    return {
      ...value,
      label: value.label
        .replace("Yes", "Sim")
        .replace("No", "Não"),
    }
  })

  return (
    <Select
      label={
        index < 1 &&
        `${question.label} ${question.required ? "*" : ""}`
      }
      options={selectOptions}
      onChange={event => {
        const checks = []
        event.forEach(select => {
          checks.push(select.value)
        })
        handleSelectChange(checks, field.name)
      }}
      Styles={{ marginBottom: 16 }}
      error={formErrors[field.name]}
      isMulti={true}
    />
  )
}
