import InputFile from "../InputFile"
import React from "react"
import { LabelView } from "../JobQuestionnaire/styles"

export const QuestionInputFile = ({ question, index, handleInputChange, field, formErrors }) => {
  return (
    <React.Fragment key={`card-technology-container-${index}`}>
      {index > 0 && <LabelView>Ou</LabelView>}
      <InputFile
        name={field.name}
        accept=".pdf, .doc, .docx, .txt, .rtf"
        placeholder={`${index < 1 && question.label} ${
          question.required ? "*" : ""
        }`}
        styles={{ maxWidth: 496, marginBottom: 16 }}
        handleFunction={handleInputChange}
        error={formErrors[field.name]}
      />
    </React.Fragment>
  )
}
